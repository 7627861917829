<template>
  <div>
    <div class="d-block">
      <div
        class="
          text-xs-h5 text-sm-h5 text-md-h4 text-lg-4 text-xl-4
          mb-3
          success--text
          d-inline
        "
      >
        <v-btn
          color="success"
          text
          icon
          class="mr-3"
          @click="RedirictSyllabusDetails"
          large
        >
          <v-icon>mdi-arrow-left</v-icon> </v-btn
        >Подробная информация о {{ $route.params.sid }} урок
        <v-btn
          color="success"
          text
          icon
          class="ml-3 edit_btn"
          @click="edit = !edit"
          large
          v-if="
            !is_post_view &&
            $store.getters.profile.user_access_level == 'training_officer'
          "
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-if="edit || is_post_view">
      <v-form @submit.stop.prevent="Save">
        <v-container fluid>
          <v-text-field v-model="form.title" label="Название программы">
          </v-text-field>
          <v-textarea
            v-model="form.content"
            label="Содержание программы"
          ></v-textarea>
          <v-file-input
            label="Файл"
            prepend-icon="mdi-presentation"
            v-model="form.file"
            truncate-length="15"
          ></v-file-input>
          <v-subheader v-if="syllabus.file"
            >Current:
            <a
              :href="api + syllabus.file"
              target="_blank"
              class="ml-1 text-decoration-none text-truncate"
              rel="noopener noreferrer"
            >
              {{ syllabus.file.split("/")[2] }}</a
            ></v-subheader
          >
          <v-file-input
            label="Видео"
            v-model="form.video"
            truncate-length="15"
            prepend-icon="mdi-video"
          ></v-file-input>
          <v-subheader v-if="syllabus.file"
            >Текущий:
            <a
              :href="api + syllabus.video"
              target="_blank"
              class="ml-1 text-decoration-none text-truncate"
              rel="noopener noreferrer"
            >
              {{ syllabus.video.split("/")[2] }}</a
            ></v-subheader
          >
          <div class="d-flex flex-row-reverse">
            <v-btn type="submit" color="success">Сохранить</v-btn>
            <v-btn
              v-if="is_post_view"
              @click="SaveAndAddNext"
              class="mr-3"
              color="success lighten-2"
              >Сохранить и добавить следующий</v-btn
            >
          </div>
        </v-container>
      </v-form>
    </div>
    <div v-else>
      <v-card flat tile>
        <v-card-title>Название</v-card-title>
        <v-card-text>{{ syllabus.title }}</v-card-text>
        <v-card-title>Содержание</v-card-title>
        <v-card-text>{{ syllabus.content }}</v-card-text>
        <v-card-title v-if="syllabus.file || syllabus.video">Файлы</v-card-title>
        <v-card-text>
          <v-row>
            <v-col><iframe allowfullscreen loading="lazy" class="frame" v-if="syllabus.file" width="100%" height="500px" :src="api + syllabus.file" /></v-col>
            <v-col><iframe allowfullscreen loading="lazy" class="frame" v-if="syllabus.video" width="100%" height="500px" :src="api + syllabus.video" /></v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      absolute
      right
      top
      color="green"
      elevation="6"
      multi-line
      tile
      class="snackbar"
      transition="slide-x-reverse-transition"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import {
  SYLLABUS_GET_ID,
  SYLLABUS_PUT,
  SYLLABUS_POST,
} from "@/store/actions/syllabus";
export default {
  data() {
    return {
      edit: false,
      is_post_view: false,
      snackbar: false,
      snackbar_text: "",
      syllabus: {},
      api: process.env.VUE_APP_API_URL.slice(0, -1),
      form: {
        id: "",
        title: "",
        content: "",
        file: null,
        video: null,
      },
    };
  },
  
  methods: {
    async LoadSyllabus() {
      let resp = await this.$dispatch(SYLLABUS_GET_ID, {
        number: this.$route.params.sid,
        teacher: this.$route.params.id,
      });
      if (resp.result.length) {
        this.syllabus = resp.result[0];
        this.form.id = resp.result[0].id;
        this.form.title = resp.result[0].title;
        this.form.content = resp.result[0].content;
      } else {
        if (
          this.$store.getters.profile.user_access_level == "training_officer"
        ) {
          this.is_post_view = true;
        } else {
          this.$router.push({
            name: "Syllabus",
            params: { id: this.$route.params.id },
          });
        }
      }
    },
    RedirictSyllabusDetails() {
      this.$router.push({
        name: "Syllabus",
        params: { id: this.$route.params.id },
      });
    },
    async Save() {
      if (!this.form.file) {
        delete this.form.file;
      }
      if (!this.form.video) {
        delete this.form.video;
      }
      let resp;
      if (this.is_post_view) {
        delete this.form.id;
        this.form.teacher = this.$route.params.id;
        this.form.number = this.$route.params.sid;
        resp = await this.$dispatch(SYLLABUS_POST, this.form);
      } else {
        resp = await this.$dispatch(SYLLABUS_PUT, this.form);
      }
      if (resp.success) {
        this.LoadSyllabus();
        if (this.is_post_view) {
          this.snackbar_text =
            resp.result.number + " урок успешно добавлена!";
          this.is_post_view = false;
        } else {
          this.snackbar_text =
            resp.result.number + " урок успешно обновлена!";
          this.edit = false;
        }
        this.snackbar = true;
      } else {
        alert("Что-то пошло не так, попробуйте еще раз позже!");
      }
    },
    SaveAndAddNext() {
      this.Save();
      for (const field in this.form) {
        this.form[field] = null;
      }
      this.$router.push({
        name: "SyllabusDetails",
        params: {
          id: this.$route.params.id,
          sid: Number(this.$route.params.sid) + 1,
        },
      });
    },
    load(val) {
      this.pageNum = val;
    },
    setScale() {
      this.$refs.pdf.changeScale(this.scale);
    },
    scroll(val) {
      this.currentNum = val;
    },
    pre() {
      this.$refs.pdf.prePage();
    },
    next() {
      this.$refs.pdf.nextPage();
    },
    pageChange(val) {
      this.$refs.pdf.goToPage(val);
    },
    print() {
      this.$refs.pdf.printFile();
    },
  },
  created() {
    this.LoadSyllabus();
  },
};
</script>

<style scoped>
.edit_btn {
  float: right;
}
.frame{
  border: none;
}
</style>